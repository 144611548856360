import { USER_ROLE } from '@/enums/user.enum';
import { CategoryNames, MenuNames } from '@/routers/RouteCategoryName.enum';
import AddIcon from '@mui/icons-material/Add';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import SettingsIcon from '@mui/icons-material/Settings';
import TopicIcon from '@mui/icons-material/Topic';
import SpaOutlinedIcon from '@mui/icons-material/SpaOutlined';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import PlaceIcon from '@mui/icons-material/Place';
import ListIcon from '@mui/icons-material/List';
import React, { ComponentType } from 'react';
import { matchPath } from 'react-router-dom';
import { Action, BusinessModule } from '@enums/casl.enum';
import { I18N } from '@enums/i18n.enum';
import { t } from 'i18next';

export interface CustomRoute {
  category?: CategoryNames;
  mobileCategory?: CategoryNames;
  path: string;
  name: MenuNames;
  title: string;
  exact: boolean;
  component: ComponentType<any>;
  isLayout: boolean;
  isMenu: boolean;
  isMobileMenu: boolean;
  isGuarded: boolean;
  disableMobileMenuForRoles?: USER_ROLE[];
  userRoles?: USER_ROLE[];
  redirect?: boolean;
  modules?: Array<BusinessModule>;
  action?: Action;
}

export interface RouteCategory {
  name: CategoryNames;
  icon: React.ReactElement;
  dropdown?: boolean;
}

export const routeCategories: RouteCategory[] = [
  {
    name: CategoryNames.MY_PROJECTS,
    icon: <TopicIcon />,
    dropdown: true,
  },
  {
    name: CategoryNames.TRANSACTIONS,
    icon: <CompareArrowsIcon />,
    dropdown: true,
  },
  {
    name: CategoryNames.REGISTER,
    icon: <AddIcon />,
    dropdown: true,
  },
  {
    name: CategoryNames.DECLARATIONS,
    icon: <PlaylistAddIcon />,
    dropdown: true,
  },
  {
    name: CategoryNames.ANALYSIS,
    icon: <SpaOutlinedIcon />,
    dropdown: false,
  },
  {
    name: CategoryNames.SETTINGS,
    icon: <SettingsIcon />,
    dropdown: true,
  },
  {
    name: CategoryNames.MAP_VIEW,
    icon: <PlaceIcon />,
    dropdown: false,
  },
  {
    name: CategoryNames.MAP,
    icon: <PlaceIcon />,
    dropdown: false,
  },
  {
    name: CategoryNames.LOG,
    icon: <ListIcon />,
    dropdown: false,
  },
  {
    name: CategoryNames.EXCHANGES,
    icon: <CompareArrowsIcon />,
    dropdown: false,
  },
];

export const guardRoutes: CustomRoute[] = [
  {
    category: CategoryNames.HOME,
    path: '/home/supply/:id',
    name: MenuNames.HOME_SUPPLY_INFO,
    title: 'Supply Search',
    exact: true,
    component: React.lazy(() => import('@/pages/home/Supply/Info')),
    isLayout: false,
    isMenu: false,
    isMobileMenu: false,
    isGuarded: true,
    userRoles: [
      USER_ROLE.ADMIN,
      USER_ROLE.COMPANY,
      USER_ROLE.USER,
      USER_ROLE.PROJECT_LEAD,
      USER_ROLE.DRIVER,
    ],
    modules: [BusinessModule.Marketplace],
    action: Action.Read,
  },
  {
    category: CategoryNames.HOME,
    path: '/home/request/:id',
    name: MenuNames.HOME_REQUEST_INFO,
    title: 'Request Search',
    exact: true,
    component: React.lazy(() => import('@/pages/home/Request/Info')),
    isLayout: false,
    isMenu: false,
    isMobileMenu: false,
    isGuarded: true,
    userRoles: [
      USER_ROLE.ADMIN,
      USER_ROLE.COMPANY,
      USER_ROLE.USER,
      USER_ROLE.PROJECT_LEAD,
      USER_ROLE.DRIVER,
    ],
    modules: [BusinessModule.Marketplace],
    action: Action.Read,
  },
  {
    category: CategoryNames.DECLARATIONS,
    path: '/declaration/declaration',
    name: MenuNames.DECLARATION_OVERVIEW,
    title: 'Declarations',
    exact: true,
    component: React.lazy(() => import('@/pages/declaration/Declaration')),
    isLayout: false,
    isMenu: true,
    isGuarded: true,
    isMobileMenu: false,
    userRoles: [
      USER_ROLE.ADMIN,
      USER_ROLE.COMPANY,
      USER_ROLE.CONTACT,
      USER_ROLE.USER,
      USER_ROLE.PROJECT_LEAD,
      USER_ROLE.DRIVER,
    ],
    modules: [BusinessModule.Declaration],
    action: Action.Read,
  },
  {
    path: '/declaration/declaration/new',
    name: MenuNames.DECLARATION_CREATE,
    title: 'Declaration create',
    exact: true,
    component: React.lazy(
      () => import('@/pages/declaration/DeclarationCreate')
    ),
    isLayout: false,
    isMenu: false,
    isGuarded: false,
    isMobileMenu: false,
    userRoles: [
      USER_ROLE.ADMIN,
      USER_ROLE.COMPANY,
      USER_ROLE.USER,
      USER_ROLE.PROJECT_LEAD,
      USER_ROLE.DRIVER,
    ],
    modules: [BusinessModule.Declaration],
    action: Action.Create,
  },
  {
    path: '/declaration/declaration/:id',
    name: MenuNames.DECLARATION_EDIT,
    title: 'Declaration edit',
    exact: true,
    component: React.lazy(() => import('@/pages/declaration/DeclarationEdit')),
    isLayout: false,
    isMenu: false,
    isGuarded: true,
    isMobileMenu: false,
    userRoles: [
      USER_ROLE.ADMIN,
      USER_ROLE.COMPANY,
      USER_ROLE.USER,
      USER_ROLE.PROJECT_LEAD,
      USER_ROLE.DRIVER,
      USER_ROLE.CONTACT,
    ],
    modules: [BusinessModule.Declaration],
    action: Action.Update,
  },
  {
    category: CategoryNames.DECLARATIONS,
    path: '/declaration/customer',
    name: MenuNames.DECLARATION_CUSTOMER,
    title: 'Customers',
    exact: true,
    component: React.lazy(
      () => import('@/pages/declaration/Customer/Overview')
    ),
    isLayout: false,
    isMenu: true,
    isGuarded: true,
    isMobileMenu: false,
    userRoles: [
      USER_ROLE.ADMIN,
      USER_ROLE.COMPANY,
      USER_ROLE.USER,
      USER_ROLE.PROJECT_LEAD,
      USER_ROLE.DRIVER,
    ],
    modules: [BusinessModule.Declaration],
    action: Action.Read,
  },
  {
    category: CategoryNames.DECLARATIONS,
    path: '/declaration/resources',
    name: MenuNames.DECLARATION_RESOURCES,
    title: 'Resources',
    exact: true,
    component: React.lazy(() => import('@/pages/declaration/Resource')),
    isLayout: false,
    isMenu: true,
    isGuarded: true,
    isMobileMenu: false,
    userRoles: [
      USER_ROLE.ADMIN,
      USER_ROLE.COMPANY,
      USER_ROLE.USER,
      USER_ROLE.PROJECT_LEAD,
      USER_ROLE.DRIVER,
      USER_ROLE.CONTACT,
    ],
    modules: [BusinessModule.Declaration],
    action: Action.Read,
  },
  {
    path: '/declaration/:declarationId/reception-control/new',
    name: MenuNames.DECLARATION_RECEPTION_CONTROL_CREATE,
    title: '',
    exact: true,
    component: React.lazy(
      () => import('@/pages/declaration/ReceptionControlCreate')
    ),
    isLayout: false,
    isMenu: false,
    isGuarded: true,
    isMobileMenu: false,
    userRoles: [
      USER_ROLE.ADMIN,
      USER_ROLE.COMPANY,
      USER_ROLE.USER,
      USER_ROLE.PROJECT_LEAD,
      USER_ROLE.DRIVER,
    ],
    modules: [BusinessModule.Declaration],
    action: Action.Create,
  },
  {
    path: '/declaration/:declarationId/reception-control/edit/:id',
    name: MenuNames.DECLARATION_RECEPTION_CONTROL_EDIT,
    title: '',
    exact: true,
    component: React.lazy(
      () => import('@/pages/declaration/ReceptionControlEdit')
    ),
    isLayout: false,
    isMenu: false,
    isGuarded: true,
    isMobileMenu: false,
    userRoles: [
      USER_ROLE.ADMIN,
      USER_ROLE.COMPANY,
      USER_ROLE.USER,
      USER_ROLE.PROJECT_LEAD,
      USER_ROLE.DRIVER,
    ],
    modules: [BusinessModule.Declaration],
    action: Action.Update,
  },
  {
    category: CategoryNames.MY_PROJECTS,
    path: '/my-projects',
    name: MenuNames.MY_PROJECTS,
    title: 'My projects',
    exact: true,
    component: React.lazy(() => import('@/pages/my-project')),
    isLayout: false,
    isMenu: true,
    isMobileMenu: true,
    disableMobileMenuForRoles: [USER_ROLE.DRIVER],
    isGuarded: true,
    userRoles: [
      USER_ROLE.ADMIN,
      USER_ROLE.COMPANY,
      USER_ROLE.USER,
      USER_ROLE.PROJECT_LEAD,
      USER_ROLE.DRIVER,
    ],
    modules: [BusinessModule.Shared],
    action: Action.Read,
  },
  {
    category: CategoryNames.MY_PROJECTS,
    path: '/my-storages',
    name: MenuNames.MY_STORAGES,
    title: 'My storages',
    exact: true,
    component: React.lazy(() => import('@/pages/my-project/MyStorages')),
    isLayout: false,
    isMenu: true,
    isMobileMenu: false,
    isGuarded: true,
    userRoles: [
      USER_ROLE.ADMIN,
      USER_ROLE.COMPANY,
      USER_ROLE.USER,
      USER_ROLE.PROJECT_LEAD,
      USER_ROLE.DRIVER,
    ],
    modules: [BusinessModule.Shared],
    action: Action.Read,
  },
  {
    category: CategoryNames.REGISTER,
    path: '/register/supply',
    name: MenuNames.REGISTER_SUPPLY,
    title: 'Supply',
    exact: true,
    component: React.lazy(() => import('@/pages/register/Supply')),
    isLayout: false,
    isMenu: true,
    isMobileMenu: true,
    disableMobileMenuForRoles: [USER_ROLE.DRIVER],
    isGuarded: true,
    userRoles: [
      USER_ROLE.ADMIN,
      USER_ROLE.COMPANY,
      USER_ROLE.USER,
      USER_ROLE.PROJECT_LEAD,
      USER_ROLE.DRIVER,
    ],
    modules: [BusinessModule.Marketplace],
    action: Action.Read,
  },
  {
    category: CategoryNames.REGISTER,
    path: '/register/supply/new',
    name: MenuNames.REGISTER_SUPPLY_NEW,
    title: 'Create supply',
    exact: true,
    component: React.lazy(() => import('@/pages/register/Supply/CreateSupply')),
    isLayout: false,
    isMenu: false,
    isMobileMenu: false,
    isGuarded: true,
    userRoles: [
      USER_ROLE.ADMIN,
      USER_ROLE.COMPANY,
      USER_ROLE.USER,
      USER_ROLE.PROJECT_LEAD,
      USER_ROLE.DRIVER,
    ],
    modules: [BusinessModule.Marketplace],
    action: Action.Create,
  },
  {
    category: CategoryNames.REGISTER,
    path: '/register/supply/:id',
    name: MenuNames.REGISTER_SUPPLY_INFO,
    title: 'Supply Info',
    exact: true,
    component: React.lazy(() => import('@/pages/register/Supply/Info')),
    isLayout: false,
    isMenu: false,
    isMobileMenu: false,
    isGuarded: true,
    userRoles: [
      USER_ROLE.ADMIN,
      USER_ROLE.COMPANY,
      USER_ROLE.USER,
      USER_ROLE.PROJECT_LEAD,
      USER_ROLE.DRIVER,
    ],
    modules: [BusinessModule.Marketplace],
    action: Action.Read,
  },
  {
    category: CategoryNames.REGISTER,
    path: '/register/request',
    name: MenuNames.REGISTER_REQUEST,
    title: 'Request',
    exact: true,
    component: React.lazy(() => import('@/pages/register/Request')),
    isLayout: false,
    isMenu: true,
    isMobileMenu: false,
    isGuarded: true,
    userRoles: [
      USER_ROLE.ADMIN,
      USER_ROLE.COMPANY,
      USER_ROLE.USER,
      USER_ROLE.PROJECT_LEAD,
      USER_ROLE.DRIVER,
    ],
    modules: [BusinessModule.Marketplace],
    action: Action.Read,
  },
  {
    category: CategoryNames.REGISTER,
    path: '/register/request/new',
    name: MenuNames.REGISTER_REQUEST_NEW,
    title: 'Create request',
    exact: true,
    component: React.lazy(
      () => import('@/pages/register/Request/CreateRequest')
    ),
    isLayout: false,
    isMenu: false,
    isMobileMenu: false,
    isGuarded: true,
    userRoles: [
      USER_ROLE.ADMIN,
      USER_ROLE.COMPANY,
      USER_ROLE.USER,
      USER_ROLE.PROJECT_LEAD,
      USER_ROLE.DRIVER,
    ],
    modules: [BusinessModule.Marketplace],
    action: Action.Create,
  },
  {
    category: CategoryNames.REGISTER,
    path: '/register/request/:id',
    name: MenuNames.REGISTER_REQUEST_INFO,
    title: 'Request Info',
    exact: true,
    component: React.lazy(() => import('@/pages/register/Request/Info')),
    isLayout: false,
    isMenu: false,
    isMobileMenu: false,
    isGuarded: true,
    userRoles: [
      USER_ROLE.ADMIN,
      USER_ROLE.COMPANY,
      USER_ROLE.USER,
      USER_ROLE.PROJECT_LEAD,
      USER_ROLE.DRIVER,
    ],
    modules: [BusinessModule.Marketplace],
    action: Action.Update,
  },
  // {
  //   category: CategoryNames.MY_ACTIVITIES,
  //   path: '/my-activities/registered',
  //   name: 'registered',
  //   title: 'Registered',
  //   exact: true,
  //   component: React.lazy(() => import('@/pages/activities/Registered')),
  //   isLayout: false,
  //   isMenu: true,
  //   isMobileMenu: true,
  //   isGuarded: true,
  // },
  {
    category: CategoryNames.TRANSACTIONS,
    path: '/transactions/inprogress',
    name: MenuNames.TRANSACTION_IN_PROGRESS,
    title: 'In progress',
    exact: true,
    component: React.lazy(() => import('@/pages/activities/Planned')),
    isLayout: false,
    isMenu: true,
    isMobileMenu: true,
    isGuarded: true,
    userRoles: [
      USER_ROLE.ADMIN,
      USER_ROLE.COMPANY,
      USER_ROLE.USER,
      USER_ROLE.PROJECT_LEAD,
      USER_ROLE.DRIVER,
    ],
    modules: [BusinessModule.Marketplace],
    action: Action.Read,
  },
  {
    category: CategoryNames.LOG,
    path: '/transactions/my-trips',
    name: MenuNames.LOG,
    title: t(I18N.MENU_MOBILE_LOG),
    exact: true,
    component: React.lazy(() => import('@/pages/activities/MyTrips')),
    isLayout: false,
    isMenu: false,
    isMobileMenu: true,
    disableMobileMenuForRoles: [
      USER_ROLE.ADMIN,
      USER_ROLE.COMPANY,
      USER_ROLE.USER,
      USER_ROLE.PROJECT_LEAD,
    ],
    isGuarded: true,
    userRoles: [
      USER_ROLE.ADMIN,
      USER_ROLE.COMPANY,
      USER_ROLE.USER,
      USER_ROLE.PROJECT_LEAD,
      USER_ROLE.DRIVER,
    ],
    modules: [BusinessModule.Marketplace],
    action: Action.Read,
  },
  {
    category: CategoryNames.MY_PROJECTS,
    mobileCategory: CategoryNames.MAP_VIEW,
    path: '/home/exchange',
    name: MenuNames.HOME_EXCHANGE,
    title: 'Exchange',
    exact: true,
    component: React.lazy(() => import('@/pages/home/Exchange')),
    isLayout: false,
    isMenu: true,
    isMobileMenu: true,
    isGuarded: true,
    userRoles: [
      USER_ROLE.ADMIN,
      USER_ROLE.COMPANY,
      USER_ROLE.USER,
      USER_ROLE.PROJECT_LEAD,
      USER_ROLE.DRIVER,
    ],
    modules: [BusinessModule.Marketplace],
    action: Action.Read,
  },
  {
    category: CategoryNames.TRANSACTIONS,
    path: '/transactions/completed',
    name: MenuNames.TRANSACTION_COMPLETED,
    title: 'Completed',
    exact: true,
    component: React.lazy(() => import('@/pages/activities/Completed')),
    isLayout: false,
    isMenu: true,
    isMobileMenu: false,
    isGuarded: true,
    userRoles: [
      USER_ROLE.ADMIN,
      USER_ROLE.COMPANY,
      USER_ROLE.USER,
      USER_ROLE.PROJECT_LEAD,
      USER_ROLE.DRIVER,
    ],
    modules: [BusinessModule.Marketplace],
    action: Action.Read,
  },
  {
    category: CategoryNames.TRANSACTIONS,
    path: '/transactions/my-trips',
    name: MenuNames.TRANSACTION_MY_TRIPS,
    title: 'My trips',
    exact: true,
    component: React.lazy(() => import('@/pages/activities/MyTrips')),
    isLayout: false,
    isMenu: true,
    isMobileMenu: false,
    isGuarded: true,
    userRoles: [
      USER_ROLE.ADMIN,
      USER_ROLE.COMPANY,
      USER_ROLE.USER,
      USER_ROLE.PROJECT_LEAD,
      USER_ROLE.DRIVER,
    ],
    modules: [BusinessModule.Marketplace],
    action: Action.Read,
  },
  {
    category: CategoryNames.TRANSACTIONS,
    path: '/transactions/reservation/:id',
    name: MenuNames.TRANSACTION_RESERVATION_TRIP,
    title: 'Reservation Trip',
    exact: true,
    component: React.lazy(() => import('@/pages/activities/ReservationTrip')),
    isLayout: false,
    isMenu: false,
    isMobileMenu: false,
    isGuarded: true,
    userRoles: [
      USER_ROLE.ADMIN,
      USER_ROLE.COMPANY,
      USER_ROLE.USER,
      USER_ROLE.PROJECT_LEAD,
      USER_ROLE.DRIVER,
    ],
    modules: [BusinessModule.Marketplace],
    action: Action.Read,
  },
  {
    category: CategoryNames.TRANSACTIONS,
    path: '/transactions/offer/:id',
    name: MenuNames.TRANSACTION_OFFER_TRIP,
    title: 'Offer Trip',
    exact: true,
    component: React.lazy(() => import('@/pages/activities/OfferTrip')),
    isLayout: false,
    isMenu: false,
    isMobileMenu: false,
    isGuarded: true,
    userRoles: [
      USER_ROLE.ADMIN,
      USER_ROLE.COMPANY,
      USER_ROLE.USER,
      USER_ROLE.PROJECT_LEAD,
      USER_ROLE.DRIVER,
    ],
    modules: [BusinessModule.Marketplace],
    action: Action.Read,
  },
  {
    category: CategoryNames.ANALYSIS,
    path: '/reports/data-extraction',
    name: MenuNames.ANALYSIS_REPORT,
    title: 'Report',
    exact: true,
    component: React.lazy(() => import('@/pages/reports/DataExtraction')),
    isLayout: false,
    isMenu: true,
    isMobileMenu: false,
    isGuarded: true,
    userRoles: [USER_ROLE.ADMIN, USER_ROLE.COMPANY],
    modules: [BusinessModule.Planner],
    action: Action.Read,
  },
  {
    category: CategoryNames.SETTINGS,
    path: '/settings/company',
    name: MenuNames.SETTING_COMPANY,
    title: 'Company',
    exact: true,
    component: React.lazy(() => import('@/pages/settings/Company')),
    isLayout: false,
    isMenu: true,
    isMobileMenu: true,
    isGuarded: true,
    userRoles: [USER_ROLE.ADMIN, USER_ROLE.COMPANY],
    modules: [BusinessModule.Shared],
    action: Action.Read,
  },
  {
    category: CategoryNames.SETTINGS,
    path: '/settings/project',
    name: MenuNames.SETTING_PROJECTS,
    title: 'Projects',
    exact: true,
    component: React.lazy(() => import('@/pages/settings/Project/List')),
    isLayout: false,
    isMenu: true,
    isMobileMenu: false,
    isGuarded: true,
    userRoles: [USER_ROLE.ADMIN, USER_ROLE.COMPANY],
    modules: [BusinessModule.Shared],
    action: Action.Read,
  },
  {
    category: CategoryNames.SETTINGS,
    path: '/settings/material',
    name: MenuNames.SETTING_MATERIAL,
    title: 'Material Overview',
    exact: true,
    component: React.lazy(() => import('@/pages/settings/Material')),
    isLayout: false,
    isMenu: true,
    isGuarded: true,
    isMobileMenu: false,
    userRoles: [USER_ROLE.ADMIN],
    modules: [BusinessModule.Shared],
    action: Action.Read,
  },
  {
    category: CategoryNames.SETTINGS,
    path: '/settings/hub',
    name: MenuNames.SETTING_HUB,
    title: 'Hub',
    exact: true,
    component: React.lazy(() => import('@/pages/settings/Hub')),
    isLayout: false,
    isMenu: true,
    isGuarded: true,
    isMobileMenu: false,
    userRoles: [USER_ROLE.ADMIN],
    modules: [BusinessModule.Shared],
    action: Action.Read,
  },
  {
    category: CategoryNames.SETTINGS,
    path: '/settings/transport',
    name: MenuNames.SETTING_TRANSPORT,
    title: 'Transport',
    exact: true,
    component: React.lazy(() => import('@/pages/settings/Transport/Overview')),
    isLayout: false,
    isMenu: true,
    isGuarded: true,
    isMobileMenu: false,
    userRoles: [USER_ROLE.ADMIN],
    modules: [BusinessModule.Shared],
    action: Action.Read,
  },
  {
    category: CategoryNames.SETTINGS,
    path: '/settings/travel-planner',
    name: MenuNames.SETTING_EMISSIONS_CALCULATOR,
    title: 'Travel planner',
    exact: true,
    component: React.lazy(() => import('@/pages/settings/TravelPlanner')),
    isLayout: false,
    isMenu: true,
    isGuarded: true,
    isMobileMenu: false,
    userRoles: [USER_ROLE.ADMIN],
    modules: [BusinessModule.Shared],
    action: Action.Read,
  },
  {
    category: CategoryNames.SETTINGS,
    path: '/settings/user',
    name: MenuNames.SETTING_USERS,
    title: 'User',
    exact: true,
    component: React.lazy(() => import('@/pages/settings/User/Overview')),
    isLayout: false,
    isMenu: true,
    isGuarded: true,
    isMobileMenu: false,
    userRoles: [USER_ROLE.ADMIN, USER_ROLE.COMPANY, USER_ROLE.PROJECT_LEAD],
    modules: [BusinessModule.Shared],
    action: Action.Read,
  },
  {
    category: CategoryNames.SETTINGS,
    path: '/settings/companies',
    name: MenuNames.SETTING_COMPANIES,
    title: 'Companies',
    exact: true,
    component: React.lazy(() => import('@/pages/settings/Companies/Overview')),
    isLayout: false,
    isMenu: true,
    isGuarded: true,
    isMobileMenu: false,
    userRoles: [USER_ROLE.ADMIN],
    modules: [BusinessModule.Shared],
    action: Action.Read,
  },
  {
    path: '/settings/project/create',
    name: MenuNames.SETTING_PROJECT_CREATE,
    title: 'Project Create',
    exact: true,
    component: React.lazy(() => import('@/pages/settings/Project/Create')),
    isLayout: false,
    isMenu: false,
    isGuarded: true,
    isMobileMenu: false,
    userRoles: [USER_ROLE.ADMIN, USER_ROLE.COMPANY],
    modules: [BusinessModule.Shared],
    action: Action.Create,
  },
  {
    path: '/settings/project/edit/:id',
    name: MenuNames.SETTING_PROJECT_EDIT,
    title: 'Project Edit',
    exact: true,
    component: React.lazy(() => import('@/pages/settings/Project/Edit')),
    isLayout: false,
    isMenu: false,
    isGuarded: true,
    isMobileMenu: false,
    userRoles: [USER_ROLE.ADMIN, USER_ROLE.COMPANY],
    modules: [BusinessModule.Shared],
    action: Action.Update,
  },
  {
    path: '/settings/project/:id',
    name: MenuNames.SETTING_PROJECT_OVERVIEW,
    title: 'Project Overview',
    exact: true,
    component: React.lazy(() => import('@/pages/settings/Project/Overview')),
    isLayout: false,
    isMenu: false,
    isGuarded: true,
    isMobileMenu: false,
    userRoles: [USER_ROLE.ADMIN, USER_ROLE.COMPANY],
    modules: [BusinessModule.Shared],
    action: Action.Create,
  },
  {
    path: '/settings/material/create',
    name: MenuNames.SETTING_MATERIAL_CREATE,
    title: 'Material Create',
    exact: true,
    component: React.lazy(() => import('@/pages/settings/MaterialCreate')),
    isLayout: false,
    isMenu: false,
    isGuarded: true,
    isMobileMenu: false,
    userRoles: [USER_ROLE.ADMIN],
    modules: [BusinessModule.Shared],
    action: Action.Create,
  },
  {
    path: '/settings/storage/create',
    name: MenuNames.SETTING_PROJECT_CREATE,
    title: 'Storage Create',
    exact: true,
    component: React.lazy(() => import('@/pages/storage/create')),
    isLayout: false,
    isMenu: false,
    isGuarded: true,
    isMobileMenu: false,
    userRoles: [USER_ROLE.ADMIN, USER_ROLE.COMPANY],
    modules: [BusinessModule.Shared],
    action: Action.Create,
  },
  {
    path: '/settings/storage/:id',
    name: MenuNames.SETTING_PROJECT_OVERVIEW,
    title: 'Storage Overview',
    exact: true,
    component: React.lazy(() => import('@/pages/settings/Project/Overview')),
    isLayout: false,
    isMenu: false,
    isGuarded: true,
    isMobileMenu: false,
    userRoles: [USER_ROLE.ADMIN, USER_ROLE.COMPANY],
    modules: [BusinessModule.Shared],
    action: Action.Create,
  },
  {
    path: '/settings/storage/edit/:id',
    name: MenuNames.SETTING_PROJECT_EDIT,
    title: 'Storage Edit',
    exact: true,
    component: React.lazy(() => import('@/pages/storage/edit')),
    isLayout: false,
    isMenu: false,
    isGuarded: true,
    isMobileMenu: false,
    userRoles: [USER_ROLE.ADMIN, USER_ROLE.COMPANY],
    modules: [BusinessModule.Shared],
    action: Action.Update,
  },
  {
    path: '/settings/material/:id',
    name: MenuNames.SETTING_MATERIAL_EDIT,
    title: 'Material Edit',
    exact: true,
    component: React.lazy(() => import('@/pages/settings/MaterialEdit')),
    isLayout: false,
    isMenu: false,
    isGuarded: true,
    isMobileMenu: false,
    userRoles: [USER_ROLE.ADMIN],
    modules: [BusinessModule.Shared],
    action: Action.Update,
  },
  {
    path: '/settings/hub/create',
    name: MenuNames.SETTING_HUB_CREATE,
    title: 'Hub Create',
    exact: true,
    component: React.lazy(() => import('@/pages/settings/HubCreate')),
    isLayout: false,
    isMenu: false,
    isGuarded: true,
    isMobileMenu: false,
    userRoles: [USER_ROLE.ADMIN],
    modules: [BusinessModule.Shared],
    action: Action.Create,
  },
  {
    path: '/settings/hub/:id',
    name: MenuNames.SETTING_HUB_EDIT,
    title: 'Hub Edit',
    exact: true,
    component: React.lazy(() => import('@/pages/settings/HubEdit')),
    isLayout: false,
    isMenu: false,
    isGuarded: true,
    isMobileMenu: false,
    userRoles: [USER_ROLE.ADMIN],
    modules: [BusinessModule.Shared],
    action: Action.Update,
  },
  {
    path: '/settings/transport/create',
    name: MenuNames.SETTING_TRANSPORT_CREATE,
    title: 'Transport Create',
    exact: true,
    component: React.lazy(() => import('@/pages/settings/Transport/Edit')),
    isLayout: false,
    isMenu: false,
    isGuarded: true,
    isMobileMenu: false,
    userRoles: [USER_ROLE.ADMIN],
    modules: [BusinessModule.Shared],
    action: Action.Create,
  },
  {
    path: '/settings/transport/:id',
    name: MenuNames.SETTING_TRANSPORT_EDIT,
    title: 'Transport Edit',
    exact: true,
    component: React.lazy(() => import('@/pages/settings/Transport/Edit')),
    isLayout: false,
    isMenu: false,
    isGuarded: true,
    isMobileMenu: false,
    userRoles: [USER_ROLE.ADMIN],
    modules: [BusinessModule.Shared],
    action: Action.Update,
  },
  {
    path: '/profile/change-password',
    name: MenuNames.CHANGE_PASSWORD,
    title: 'ChangePassword',
    exact: false,
    component: React.lazy(() => import('@/pages/accounts/ChangePassword')),
    isLayout: false,
    isMenu: false,
    isMobileMenu: false,
    isGuarded: false,
  },
  {
    path: '/profile',
    name: MenuNames.SETTING_PROFILE,
    title: 'Profile',
    exact: true,
    component: React.lazy(() => import('@/pages/accounts/Profile')),
    isLayout: false,
    isMenu: false,
    isMobileMenu: false,
    isGuarded: true,
    modules: [BusinessModule.Shared],
  },
  {
    path: '/profile/edit/:id?',
    name: MenuNames.SETTING_PROFILE_EDIT,
    title: 'Edit profile',
    exact: true,
    component: React.lazy(() => import('@/pages/accounts/EditProfile')),
    isLayout: false,
    isMenu: false,
    isMobileMenu: false,
    isGuarded: true,
    modules: [BusinessModule.Shared],
    action: Action.Update,
  },
  {
    path: '/settings/companies/create',
    name: MenuNames.COMPANY_CREATE,
    title: 'Create company',
    exact: true,
    component: React.lazy(() => import('@/pages/settings/Companies/Create')),
    isLayout: false,
    isMenu: false,
    isMobileMenu: false,
    isGuarded: true,
    userRoles: [USER_ROLE.ADMIN, USER_ROLE.COMPANY],
    modules: [BusinessModule.Shared],
    action: Action.Create,
  },
  {
    path: '/settings/companies/:id',
    name: MenuNames.COMPANY_EDIT,
    title: 'Edit company',
    exact: true,
    component: React.lazy(() => import('@/pages/settings/Companies/Edit')),
    isLayout: false,
    isMenu: false,
    isMobileMenu: false,
    isGuarded: true,
    userRoles: [USER_ROLE.ADMIN, USER_ROLE.COMPANY],
    modules: [BusinessModule.Shared],
    action: Action.Update,
  },
  {
    path: '/terms-and-conditions',
    name: MenuNames.TERMS_AND_CONDITIONS,
    title: 'Terms and conditions',
    exact: true,
    component: React.lazy(() => import('@/pages/public/Terms')),
    isLayout: false,
    isMenu: false,
    isMobileMenu: false,
    isGuarded: false,
  },
  {
    path: '/privacy',
    name: MenuNames.PRIVACY,
    title: 'Privacy',
    exact: true,
    component: React.lazy(() => import('@/pages/public/Privacy')),
    isLayout: false,
    isMenu: false,
    isMobileMenu: false,
    isGuarded: false,
  },
  {
    path: '/use-of-cookie',
    name: MenuNames.COOKIE,
    title: 'Cookie',
    exact: true,
    component: React.lazy(() => import('@/pages/public/Cookies')),
    isLayout: false,
    isMenu: false,
    isMobileMenu: false,
    isGuarded: false,
  },
  {
    path: '/trip/:id/feedback-trip',
    name: MenuNames.TRIP_FEEDBACK,
    title: 'Trip feedback',
    exact: true,
    component: React.lazy(() => import('@/pages/activities/Feedback')),
    isLayout: false,
    isMenu: false,
    isMobileMenu: false,
    isGuarded: true,
  },
];

export const defaultRoutes: CustomRoute[] = [
  {
    path: '/register',
    name: MenuNames.REGISTER,
    title: 'Register',
    exact: true,
    component: React.lazy(() => import('@/pages/accounts/Register')),
    isLayout: false,
    isMenu: false,
    isMobileMenu: false,
    isGuarded: false,
  },
  {
    path: '/forgot-password',
    name: MenuNames.FORGOT_PASSWORD,
    title: 'ForgotPassword',
    exact: true,
    component: React.lazy(() => import('@/pages/accounts/ForgotPassword')),
    isLayout: false,
    isMenu: false,
    isMobileMenu: false,
    isGuarded: false,
  },
  {
    path: '/reset-password/:token',
    name: MenuNames.RESET_PASSWORD,
    title: 'ResetPassword',
    exact: false,
    component: React.lazy(() => import('@/pages/accounts/ResetPassword')),
    isLayout: false,
    isMenu: false,
    isMobileMenu: false,
    isGuarded: false,
  },
  {
    path: '/verify-email/:token',
    name: MenuNames.VERIFY_EMAIL,
    title: 'ResetPassword',
    exact: false,
    component: React.lazy(() => import('@/pages/accounts/VerifyEmail')),
    isLayout: false,
    isMenu: false,
    isMobileMenu: false,
    isGuarded: false,
  },
  // Always keep default route '/' at last so that all mismatched path will render Login page without redirecting
  {
    path: '/login',
    name: MenuNames.LOGIN,
    title: 'Login',
    exact: false,
    component: React.lazy(() => import('@/pages/accounts/Login')),
    isLayout: false,
    isMenu: false,
    isMobileMenu: false,
    isGuarded: false,
  },
  {
    path: '/',
    name: MenuNames.VISITOR,
    title: 'Visitor',
    exact: false,
    component: React.lazy(() => import('@/pages/home/Exchange')),
    isLayout: false,
    isMenu: false,
    isMobileMenu: false,
    isGuarded: true,
  },
];

export const publicRoutes: CustomRoute[] = [
  {
    path: '/terms-and-conditions',
    name: MenuNames.TERMS_AND_CONDITIONS,
    title: 'Terms and conditions',
    exact: true,
    component: React.lazy(() => import('@/pages/public/Terms')),
    isLayout: false,
    isMenu: false,
    isMobileMenu: false,
    isGuarded: false,
  },
  {
    path: '/privacy',
    name: MenuNames.PRIVACY,
    title: 'Privacy',
    exact: true,
    component: React.lazy(() => import('@/pages/public/Privacy')),
    isLayout: false,
    isMenu: false,
    isMobileMenu: false,
    isGuarded: false,
  },
  {
    path: '/use-of-cookie',
    name: MenuNames.PRIVACY,
    title: 'Use of cookies',
    exact: true,
    component: React.lazy(() => import('@/pages/public/Cookies')),
    isLayout: false,
    isMenu: false,
    isMobileMenu: false,
    isGuarded: false,
  },
];

export const currentRoute = () => {
  return guardRoutes.find((route) =>
    matchPath(window.location.pathname, route)
  );
};

export const getRouteByPathname = (pathname: string) => {
  const matchedRoute = guardRoutes.find((route) => matchPath(pathname, route));

  return matchedRoute;
};

export const getDefaultRouteByCategory = (
  category: CategoryNames,
  routes: CustomRoute[]
) => {
  // const matchedRoutes = guardRoutes.find(
  const matchedRoutes = routes.find(
    (route) =>
      (route.category && route.category === category) ||
      (route.mobileCategory && route.mobileCategory === category)
  );

  return matchedRoutes;
};
