import React, { FC, useEffect } from 'react';
import * as serviceWorker from '../../../serviceWorkerRegistration';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { I18N } from '@/enums/i18n.enum';

const CHECK_INTERVAL = 0.5 * 60 * 1000;

const ServiceWorkerWrapper: FC = () => {
  const [showReload, setShowReload] = React.useState(false);
  const [waitingWorker, setWaitingWorker] =
    React.useState<ServiceWorker | null>(null);

  const { t } = useTranslation();
  const { BUTTONS_UPDATE, NOTIFY_NEW_VERSION } = I18N;

  const onSWUpdate = (registration: ServiceWorkerRegistration) => {
    if (registration.waiting) {
      console.log('New service worker is waiting to activate.');
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      setShowReload(true);
      setWaitingWorker(registration.waiting);
    } else {
      console.warn('No waiting service worker found.');
    }
  };

  const checkForWaitingServiceWorker = () => {
    navigator.serviceWorker.getRegistration().then((registration) => {
      if (registration?.waiting) {
        console.log('Existing service worker is waiting to activate.');
        setShowReload(true);
        setWaitingWorker(registration.waiting);
      }
    });
  };

  useEffect(() => {
    // Register the service worker and handle updates
    serviceWorker.register({ onUpdate: onSWUpdate });

    // Check if there is already a waiting service worker
    checkForWaitingServiceWorker();

    // Only run the interval function if the app is running as a PWA
    const isStandalone = window.matchMedia(
      '(display-mode: standalone)'
    ).matches;
    if (isStandalone) {
      console.log(
        'App is running in standalone mode. Starting periodic update checks.'
      );
      const interval = setInterval(() => {
        navigator.serviceWorker.getRegistration().then((registration) => {
          if (registration) {
            console.log('Fetching updates for the service worker...');
            registration.update().then(() => {
              if (registration?.waiting) {
                console.log('Existing service worker is waiting to activate.');
                setShowReload(true);
                setWaitingWorker(registration.waiting);
              }
            });
          }
        });
      }, CHECK_INTERVAL);

      return () => {
        clearInterval(interval);
      };
    } else {
      console.log(
        'App is not running in standalone mode. Skipping periodic update checks.'
      );
    }
  }, []);

  const reloadPage = () => {
    if (waitingWorker) {
      console.log('Sending SKIP_WAITING message to service worker...');
      waitingWorker.postMessage({ type: 'SKIP_WAITING' });
      setShowReload(false);
      console.log('Reloading the page...');
      window.location.reload();
    } else {
      console.error('No waiting worker found. Cannot reload the page.');
    }
  };

  const handleClose = () => {
    setShowReload(false);
  };

  return (
    <Snackbar
      open={showReload}
      message={t(NOTIFY_NEW_VERSION)}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      action={
        <Box sx={{ p: 1 }}>
          <Button
            sx={{ mr: 1 }}
            color='primary'
            size='small'
            variant='contained'
            onClick={reloadPage}
          >
            {t(BUTTONS_UPDATE)}
          </Button>
          <IconButton aria-label='close' color='inherit' onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      }
    />
  );
};

export default ServiceWorkerWrapper;
