import { USER_ROLE } from '@/enums/user.enum';
import { INIT_LOCATION } from './location.constants';
import { InfoUser } from '@/dto/users/InfoUser.dto';
import { UserFormValidation } from '@/validations/user.validation';
import { CreateNewPassword } from '@/dto/users/CreateNewPassword.dto';
import { InfoInviteUser } from '@/dto/users/InfoInviteUser.dto';
import { InfoContactPerson } from '@/dto/users/InfoContactPerson.dto';

export const INIT_USER_FORM: InfoUser = {
  id: 0,
  createdAt: new Date(),
  updatedAt: new Date(),
  email: '',
  fullName: '',
  role: USER_ROLE.USER,
  phoneNumber: '',
  companyId: 0,
  location: INIT_LOCATION,
  focusLocation: INIT_LOCATION,
  password: '',
  brrCode: '',
  companyByOrgNumber: null,
  customerId: null,
};

export const INIT_INVITE_USER_FORM: InfoInviteUser = {
  email: '',
  fullName: '',
  phoneNumber: '',
  role: USER_ROLE.USER,
  companyId: undefined,
  brrCode: '',
  companyByOrgNumber: null,
};

export const INIT_CONTACT_PERSON_FORM: InfoContactPerson = {
  email: '',
  fullName: '',
  phoneNumber: '',
  companyId: null,
  location: INIT_LOCATION,
};

export const INIT_CREATE_NEW_PASSWORD: CreateNewPassword = {
  token: '',
  password: '',
  confirmedPassword: '',
};

export const INIT_USER_FORM_ERROR: Record<string, boolean> = {
  email: false,
  fullName: false,
  phoneNumber: false,
  password: false,
  location: false,
  brrCode: false,
  companyId: false,
};

export const INIT_INVITE_USER_FORM_ERROR: Record<string, boolean> = {
  email: false,
  fullName: false,
  phoneNumber: false,
};

export const INIT_CONTACT_PERSON_FORM_ERROR: Record<string, boolean> = {
  email: false,
  fullName: false,
  phoneNumber: false,
  location: false,
};

export const INIT_CREATE_NEW_PASSWORD_ERROR: Record<string, boolean> = {
  password: false,
  confirmedPassword: false,
};

export const INIT_USER_FORM_VALIDATION: UserFormValidation = {
  errorForm: INIT_USER_FORM_ERROR || INIT_CREATE_NEW_PASSWORD_ERROR,
  isError: false,
};

export const INIT_INVITE_USER_FORM_VALIDATION: UserFormValidation = {
  errorForm: INIT_INVITE_USER_FORM_ERROR,
  isError: false,
};

export const INIT_CONTACT_PERSON_FORM_VALIDATION: UserFormValidation = {
  errorForm: INIT_CONTACT_PERSON_FORM_ERROR,
  isError: false,
};
