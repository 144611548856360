export enum CategoryNames {
  HOME = 'Home',
  REGISTER = 'Register',
  TRANSACTIONS = 'Transactions',
  ANALYSIS = 'Analysis',
  SETTINGS = 'Settings',
  MY_PROJECTS = 'My projects',
  DECLARATIONS = 'Declarations',
  MAP_VIEW = 'Map view',
  MAP = 'Map',
  LOG = 'Log',
  EXCHANGES = 'Exchanges',
}

export enum MenuNames {
  HOME_EXCHANGE = 'HOME_EXCHANGE',
  HOME_SUPPLY = 'HOME_SUPPLY',
  HOME_REQUEST = 'HOME_REQUEST',
  HOME_SUPPLY_INFO = 'HOME_SUPPLY_INFO',
  HOME_REQUEST_INFO = 'HOME_REQUEST_INFO',
  DECLARATION_OVERVIEW = 'DECLARATION_OVERVIEW',
  DECLARATION_RESOURCES = 'DECLARATION_RESOURCES',
  DECLARATION_CREATE = 'DECLARATION_CREATE',
  DECLARATION_EDIT = 'DECLARATION_EDIT',
  DECLARATION_CUSTOMER = 'DECLARATION_CUSTOMER',
  DECLARATION_RECEPTION_CONTROL_CREATE = 'DECLARATION_RECEPTION_CONTROL_CREATE',
  DECLARATION_RECEPTION_CONTROL_EDIT = 'DECLARATION_RECEPTION_CONTROL_EDIT',
  REGISTER_SUPPLY = 'REGISTER_SUPPLY',
  REGISTER_SUPPLY_NEW = 'REGISTER_SUPPLY_NEW',
  REGISTER_SUPPLY_INFO = 'REGISTER_SUPPLY_INFO',
  REGISTER_REQUEST = 'REGISTER_REQUEST',
  REGISTER_REQUEST_NEW = 'REGISTER_REQUEST_NEW',
  REGISTER_REQUEST_INFO = 'REGISTER_REQUEST_INFO',
  TRANSACTION_IN_PROGRESS = 'TRANSACTION_IN_PROGRESS',
  TRANSACTION_COMPLETED = 'TRANSACTION_COMPLETED',
  TRANSACTION_MY_TRIPS = 'TRANSACTION_MY_TRIPS',
  TRANSACTION_RESERVATION_TRIP = 'TRANSACTION_RESERVATION_TRIP',
  TRANSACTION_OFFER_TRIP = 'TRANSACTION_OFFER_TRIP',
  ANALYSIS = 'ANALYSIS',
  ANALYSIS_REPORT = 'REPORT',
  ANALYSIS_DATA_EXTRACTION = 'DATA_EXTRACTION',
  SETTING_PROJECTS = 'SETTING_PROJECTS',
  SETTING_PROJECT_OVERVIEW = 'SETTING_PROJECT_OVERVIEW',
  SETTING_PROJECT_CREATE = 'SETTING_PROJECT_CREATE',
  SETTING_PROJECT_EDIT = 'SETTING_PROJECT_EDIT',
  SETTING_MATERIAL = 'SETTING_MATERIAL',
  SETTING_HUB = 'SETTING_HUB',
  SETTING_CO2EMISSION = 'SETTING_CO2EMISSION',
  SETTING_EMISSIONS_CALCULATOR = 'SETTING_EMISSIONS_CALCULATOR',
  SETTING_MY_ACTIVITIES = 'SETTING_MY_ACTIVITIES',
  SETTING_MATERIAL_CREATE = 'SETTING_MATERIAL_CREATE',
  SETTING_MATERIAL_EDIT = 'SETTING_MATERIAL_EDIT',
  SETTING_HUB_CREATE = 'SETTING_HUB_CREATE',
  SETTING_HUB_EDIT = 'SETTING_HUB_EDIT',
  SETTING_TRANSPORT = 'SETTING_TRANSPORT',
  SETTING_TRANSPORTT = 'SETTING_TRANSPORTT',
  SETTING_TRANSPORT_CREATE = 'SETTING_TRANSPORT_CREATE',
  SETTING_TRANSPORT_EDIT = 'SETTING_TRANSPORT_EDIT',
  SETTING_PROFILE = 'SETTING_PROFILE',
  SETTING_PROFILE_EDIT = 'SETTING_PROFILE_EDIT',
  SETTING_USERS = 'SETTING_USERS',
  SETTING_COMPANIES = 'SETTING_COMPANIES',
  SETTING_COMPANY = 'SETTING_COMPANY',
  COMPANY_CREATE = 'COMPANY_CREATE',
  COMPANY_EDIT = 'COMPANY_EDIT',
  TERMS_AND_CONDITIONS = 'TERMS_AND_CONDITIONS',
  PRIVACY = 'PRIVACY',
  COOKIE = 'COOKIE',
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  VISITOR = 'VISITOR',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  RESET_PASSWORD = 'RESET_PASSWORD',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  VERIFY_EMAIL = 'VERIFY_EMAIL',
  TRIP_FEEDBACK = 'TRIP_FEEDBACK',
  MY_PROJECTS = 'MY_PROJECTS',
  MY_STORAGES = 'MY_STORAGES',
  LOG = 'LOG',
}
