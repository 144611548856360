import { BOTTOM_NAVIGATION_HEIGHT } from '@/constants/css.constants';
import {
  CustomRoute,
  getDefaultRouteByCategory,
  getRouteByPathname,
  RouteCategory,
} from '@/routers/routes';
import { BottomNavigation, BottomNavigationAction, Box } from '@mui/material';
import { getMobileTitle } from '@utils/name.utils';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { CategoryNames } from '@/routers/RouteCategoryName.enum';

interface ComponentProps {
  menuItems: CustomRoute[];
  categoryItems: RouteCategory[];
}

const BottomNavigationBar = (props: ComponentProps) => {
  const { menuItems, categoryItems } = props;
  const history = useHistory();
  const { t } = useTranslation();

  const getCategory = (item: CustomRoute): RouteCategory | undefined => {
    const mobileRouteCategory = categoryItems.find(
      (category) => category.name === item.mobileCategory
    );
    const routeCategory = categoryItems.find(
      (category) => category.name === item.category
    );
    return mobileRouteCategory ?? routeCategory;
  };

  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        <BottomNavigation
          showLabels
          value={history.location.pathname}
          onChange={(_, newValue) => {
            history.push(newValue);
          }}
          sx={{
            backgroundColor: 'primary.light',
            pl: 0.5,
            pr: 0.5,
            height: BOTTOM_NAVIGATION_HEIGHT,
          }}
        >
          {menuItems.map((item) => {
            const category = item.mobileCategory ?? item.category;
            return (
              <BottomNavigationAction
                disableRipple
                key={`${item.path}-menu-mobile`}
                label={category ? getMobileTitle(category, t) : category}
                icon={getCategory(item)?.icon}
                value={item.path}
              />
            );
          })}
        </BottomNavigation>
      </Box>
    </>
  );
};

export default BottomNavigationBar;
